import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tag from "../components/tag"

import markdownToText from "../utils/markdown-to-text"
import moment from "../utils/moment"
import truncateText from "../utils/truncate"
import { theme } from "../constants/theme"
import Logo from "../images/logo.inline.svg"
import qrcode from "../images/qrcode.png"

const Header = ({ title }) => {
  return (
    <div
      css={css`
        margin-top: 30px;
        margin-bottom: 32px;
        line-height: 28px;
        font-size: 28px;
      `}
    >
      {title}
    </div>
  )
}

const Author = ({ author, createdAt }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <div
      css={css`
        margin-right: 5px;
        height: 18px;
        line-height: 20px;
      `}
    >
      {author && author.photo ? (
        <GatsbyImage
          css={css`border-radius: 50%;`}
          image={getImage(author.photo)} alt={author.name}
        />
      ) : (
        <Logo
          css={css`
            height: 18px;
            border-radius: 50%;
          `}
        />
      )}
    </div>
    <div
      css={css`
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: ${theme.colors.primary700};
      `}
    >
      {author ? author.name || author.username : "托特"}
    </div>
    <div
      css={css`
        font-size: 14px;
        line-height: 20px;
        margin-left: 5px;
        color: ${theme.colors.primary400};
      `}
    >
      {moment(createdAt).format("LL")}
    </div>
  </div>
)

const BlogPage = ({ data }) => {
  const articles = data.allStrapiArticle.edges.map(edge => edge.node)

  if (articles.length === 0) return 0

  const [latestArticle, ...moreArticles] = articles

  return (
    <Layout
      base="/blog"
      title="托特 | 部落格"
      fluid
      nav={[{ title: "首頁", link: "/" }]}
    >
      <SEO title="托特 | 部落格 x 體驗新知" />
      <Header title="最新出爐" />
      <div
        css={css`
          display: flex;
          margin-bottom: 72px;
          @media ${theme.devices.max.laptopL} {
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            max-width: ${theme.size.tablet};
          }
        `}
      >
        <GatsbyImage
          css={css`
            width: 600px;
            height: 400px;
            border-radius: 8px;
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.03);

            @media ${theme.devices.max.laptopL} {
              width: 100%;
              height: auto;
            }
          `}
          image={getImage(latestArticle.cover)}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 32px;

            @media ${theme.devices.max.laptopL} {
              margin-top: 40px;
              margin-left: 0;
            }

            @media ${theme.devices.max.tablet} {
              margin-top: 24px;
            }
          `}
        >
          <Author
            author={latestArticle.author}
            createdAt={latestArticle.created_at}
          />
          <Link
            css={css`
              border-bottom: none;
              margin-top: 24px;
              margin-bottom: 0;
              color: ${theme.colors.primary700};
              &:hover {
                color: ${theme.colors.primary700};
              }
              @media ${theme.devices.max.tablet} {
                margin-top: 20px;
              }
            `}
            to={`/blog/${latestArticle.strapiId}/`}
          >
            <h1
              css={css`
                font-weight: 400;
                font-size: 30px;
                line-height: 40px;
                margin: 0;

                @media ${theme.devices.max.tablet} {
                  font-size: 20px;
                  line-height: 30px;
                }
              `}
            >
              {latestArticle.title}
            </h1>
          </Link>
          <Link
            css={css`
              border-bottom: none;
              margin: 20px 0 0;
              color: ${theme.colors.primary500};
              &:hover {
                color: ${theme.colors.primary500};
              }
            `}
            to={`/blog/${latestArticle.strapiId}/`}
          >
            <div
              css={css`
                font-size: 18px;
                line-height: 30px;

                @media ${theme.devices.max.tablet} {
                  font-size: 16px;
                  line-height: 26px;
                }
              `}
            >
              <div
                css={css`
                  display: block;
                  @media ${theme.devices.max.laptopL} {
                    display: none;
                  }
                `}
              >
                {truncateText(markdownToText(latestArticle.content), 150)}
              </div>
              <div
                css={css`
                  display: none;
                  @media ${theme.devices.max.laptopL} {
                    display: block;
                  }
                `}
              >
                {truncateText(markdownToText(latestArticle.content))}
              </div>
            </div>
          </Link>

          <div
            css={css`
              display: flex;
              margin-top: auto;
              margin-left: auto;

              @media ${theme.devices.max.laptopL} {
                margin-top: 40px;
              }
            `}
          >
            {latestArticle.tags.map(tag => (
              <Tag
                key={tag.name}
                css={css`
                  margin-left: 12px;
                `}
              >
                {tag.name}
              </Tag>
            ))}
          </div>
        </div>
      </div>
      <Header title="更多文章" />
      <div
        css={css`
          display: flex;
          @media ${theme.devices.max.laptopL} {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        >
          {moreArticles.map(article => {
            return (
              <div
                key={article.id}
                css={css`
                  display: flex;
                  margin-bottom: 40px;

                  @media ${theme.devices.max.tablet} {
                    flex-direction: column;
                    margin-bottom: 48px;
                  }
                `}
              >
                <GatsbyImage
                  css={css`
                    width: 345px;
                    height: 230px;
                    border-radius: 8px;
                    box-shadow: 0 0 25px rgba(0, 0, 0, 0.03);

                    @media ${theme.devices.max.tablet} {
                      width: 100%;
                      height: auto;
                    }
                  `}
                  image={getImage(article.cover)}
                />
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    margin-left: 32px;

                    @media ${theme.devices.max.tablet} {
                      margin-left: 0;
                      margin-top: 24px;
                    }
                  `}
                >
                  <Author
                    author={article.author}
                    createdAt={article.created_at}
                  />
                  <Link
                    css={css`
                      border-bottom: none;
                      margin-top: 16px;
                      margin-bottom: 0;
                      color: ${theme.colors.primary700};
                      &:hover {
                        color: ${theme.colors.primary700};
                      }

                      @media ${theme.devices.max.tablet} {
                        margin-top: 20px;
                      }
                    `}
                    to={`/blog/${article.strapiId}/`}
                  >
                    <h2
                      css={css`
                        font-weight: 400;
                        line-height: 30px;
                        font-size: 20px;
                        margin: 0;
                      `}
                    >
                      {article.title}
                    </h2>
                  </Link>
                  <Link
                    css={css`
                      margin-top: 12px;
                      border-bottom: none;
                      color: ${theme.colors.primary500};
                      &:hover {
                        color: ${theme.colors.primary500};
                      }

                      @media ${theme.devices.max.tablet} {
                        margin: 20px 0 0;
                      }
                    `}
                    to={`/blog/${article.strapiId}/`}
                  >
                    <div
                      css={css`
                        font-size: 16px;
                        line-height: 26px;
                      `}
                    >
                      <div
                        css={css`
                          display: block;
                          @media ${theme.devices.max.tablet} {
                            display: none;
                          }
                        `}
                      >
                        {truncateText(markdownToText(article.content), 49)}
                      </div>
                      <div
                        css={css`
                          display: none;
                          @media ${theme.devices.max.tablet} {
                            display: block;
                          }
                        `}
                      >
                        {truncateText(markdownToText(article.content))}
                      </div>
                    </div>
                  </Link>
                  <div
                    css={css`
                      margin-top: auto;
                      display: flex;

                      @media ${theme.devices.max.tablet} {
                        margin-top: 40px;
                        margin-left: auto;
                      }
                    `}
                  >
                    {article.tags.map(tag => (
                      <Tag
                        key={tag.name}
                        css={css`
                          margin-right: 12px;
                          font-size: 12px;

                          @media ${theme.devices.max.tablet} {
                            font-size: 14px;
                          }
                        `}
                      >
                        {tag.name}
                      </Tag>
                    ))}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div
          css={css`
            position: sticky;
            top: 85px;
            align-self: start;
            margin-left: 32px;
            flex: 0 0 327px;

            @media ${theme.devices.max.laptopL} {
              flex: 1;
              margin-left: 0;
              margin-top: 40px;
            }
          `}
        >
          <div
            css={css`
              width: 100%;
              background: white;
              border-radius: 8px;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
            `}
          >
            <div
              css={css`
                text-align: center;
                padding: 50px 0;
                border-radius: 8px;
                background: ${theme.colors.primary100};
              `}
            >
              <Logo
                css={css`
                  height: 80px;
                  width: 80px;
                  border-radius: 50%;
                `}
              />
            </div>

            <div
              css={css`
                padding: 25px;
              `}
            >
              <div
                css={css`
                  margin: 10px 0;
                  font-weight: bold;
                  font-size: 22px;
                  margin-bottom: 10px;
                  color: ${theme.colors.primary700};
                `}
              >
                托特
                <span
                  css={css`
                    margin-left: 5px;
                    font-size: 16px;
                  `}
                >
                  尋找知識夥伴，啟動斜槓人生！
                </span>
              </div>
              <div
                css={css`
                  margin: 10px 0;
                  font-size: 16px;
                  line-height: 26px;
                  color: ${theme.colors.primary500};
                `}
              >
                這裡聚集了擁有不同知識領域以及渴望學習的朋友。
                結交新朋友、學習新知識，
                想學什麼第二項技能，成為斜槓青年？現在就來看看！
              </div>
              <a href="https://app.thoth.tw" target="_blank" rel="noreferrer">
                <div
                  css={css`
                    padding: 10px;
                    margin: 40px 0 0;
                    border-radius: 8px;
                    text-align: center;
                    background: ${theme.colors.primary100};
                    color: ${theme.colors.primary600};
                    font-weight: bold;
                    letter-spacing: 2px;
                    cursor: pointer;

                    &:hover {
                      background: ${theme.colors.primary200};
                      color: ${theme.colors.primary700};
                      transition: 0.5s all;
                    }
                  `}
                >
                  立即尋找夥伴
                </div>
              </a>
            </div>
          </div>
          <div
            css={css`
              width: 100%;
              margin-top: 40px;
              padding: 25px 0;
              text-align: center;
              background: white;
              border-radius: 8px;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
            `}
          >
            <div
              css={css`
                font-weight: bold;
                font-size: 18px;
                margin: 0 20px;
                color: ${theme.colors.primary600};
              `}
            >
              想要擁有更好的用戶體驗嗎? 現在就來下載托特 APP!
            </div>
            <div
              css={css`
                width: 150px;
                height: 150px;
                margin: 20px auto 0;
              `}
            >
              <img src={qrcode} alt="qrcode" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiArticle(
      sort: { fields: created_at, order: DESC }
      filter: { status: { eq: "Published" } }
    ) {
      edges {
        node {
          strapiId
          id
          title
          content
          cover {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 680)
            }
          }
          created_at
          tags {
            name
          }
          author {
            name
            photo {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 18, height: 18)
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
